<template>
  <div class="flex w-full justify-center">
    <div class="flex w-full flex-col items-center">
      <SlideOver
        ref="billingSlideOver"
        :enableMobileBottomToTopTranslation="true"
        @close="showBilling = false"
        :open="showBilling"
      >
        <div
          class="flex h-full w-full flex-col items-center justify-start gap-6 overflow-y-scroll rounded-t-2xl bg-ds-background p-6 md:rounded-none"
        >
          <div class="flex w-full items-center justify-between gap-4">
            <div class="flex items-center gap-4">
              <svg
                @click="closeBilling"
                class="hidden cursor-pointer md:block"
                width="14"
                height="27"
                viewBox="0 0 14 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 25.5L13 13.5L1 1.5"
                  stroke="#0078FB"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <h1>Billing details</h1>
            </div>
            <span
              @click="closeBilling"
              class="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-60 text-gray-70 md:hidden"
            >
              <inline-svg class="h-4 w-4" src="/img/icons/close.svg" />
            </span>
          </div>
          <div
            class="border flex w-full flex-wrap items-center justify-between gap-3 rounded-lg border-ds-outline-primary bg-ds-foreground p-4"
          >
            <div class="flex flex-col justify-start gap-4">
              <div class="flex items-center gap-4">
                <p class="text-xl font-extrabold">{{ userPlan }} plan</p>
                <div
                  class="flex-initial rounded-full px-3 py-1 text-center"
                  :style="getSubscriptionStatusConfig(userPlan)"
                >
                  <p>{{ getSubscriptionStatusConfig(userPlan).title }}</p>
                </div>
              </div>
              <p v-if="nextPaymentDetailsText">{{ nextPaymentDetailsText }}</p>
            </div>
            <div class="flex flex-row items-center justify-start gap-3 sm:flex-col-reverse">
              <a
                v-if="showCancelSubscriptionButton"
                @click="cancelSubscription"
                class="text-blues-40"
                >Cancel subscription</a
              >
              <new-button @click="openPlansModal" class="">Update plan</new-button>
            </div>
          </div>
          <template v-if="subscriptionDetails && subscriptionDetails.payment_information">
            <div class="flex w-full flex-wrap items-center gap-6">
              <div class="border flex-1 space-y-4 rounded-lg border-ds-outline-primary p-4">
                <p class="text-xl font-extrabold">Payment method</p>
                <span
                  class="flex items-center gap-4 text-lg"
                  v-if="subscriptionDetails.payment_information.payment_method === 'card'"
                >
                  <p class="uppercase">
                    {{ getPaymentMethodName(subscriptionDetails.payment_information) }}
                  </p>
                  <p>**** {{ subscriptionDetails.payment_information.last_four_digits }}</p>
                </span>
                <new-button @click="updatePaymentMethod" type="secondary" class="w-full"
                  >Update</new-button
                >
              </div>
              <div
                class="border h-188 flex-1 space-y-4 rounded-lg border-ds-outline-primary p-4 sm:h-full"
              >
                <p class="text-xl font-extrabold">Billing information</p>
                <p class="text-lg">{{ subscriptionDetails.user_email }}</p>
              </div>
            </div>
            <div class="border w-full rounded-lg border-ds-outline-primary p-6 pb-0">
              <p class="mb-4 text-xl font-extrabold">Invoices</p>
              <div
                class="divide-y flex flex-col-reverse gap-4 divide-y-reverse divide-ds-outline-primary"
              >
                <div
                  v-for="(payment, index) in subscriptionDetails.payment_history"
                  :key="index"
                  class="flex items-center justify-between pb-4"
                >
                  <p>{{ payment.payout_date }}</p>
                  <div class="flex items-center gap-2">
                    <p>{{ payment.amount }} {{ payment.currency }}</p>
                    <div
                      class="rounded-full px-3 py-1"
                      :class="{ 'bg-green-500': payment.is_paid, 'bg-red-600': !payment.is_paid }"
                    >
                      <p>{{ payment.is_paid ? 'PAID' : 'UNPAID' }}</p>
                    </div>
                  </div>
                  <a :href="payment.receipt_url" target="_blank" class="text-blues-40">View</a>
                </div>
              </div>
            </div>
          </template>
        </div>
      </SlideOver>
      <PlansModal
        :showPlansModal="computedShowPlansModal"
        :isSubscriptionTypeMonthly="isSubscriptionTypeMonthly"
        @close="showPlansModal = false"
        :selectedSubscriptionType="selectedSubscriptionType"
        v-on:changeSubscriptionType="changeSubscriptionType($event)"
        v-on:checkout="checkout($event)"
        :planNames="planNames"
        :shouldShowBasicPlan="shouldShowBasicPlan"
      />
      <div class="w-full md:w-591">
        <h1 class="mb-4">Subscription</h1>
      </div>
      <div class="flex h-188 w-full items-center justify-center" v-if="isLoading">
        <half-circle-spinner :size="56" :color="'#0d70e2'" style="margin: auto" />
      </div>
      <div class="flex w-full flex-col gap-8 md:w-591" v-else-if="isSubscriptionActive">
        <!-- <div
          v-if="showUpgradeToYearlyPlanNotification"
          class="flex w-full items-start justify-between gap-3 rounded-lg bg-ds-button-secondary-background p-4"
        >
          <div class="flex-initial">
            <svg class="h-5 w-5" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M16.25 9.125H18.75M16.1613 5.0625L18.3263 3.8125M16.1613 13.1875L18.3263 14.4375M3.8125 11L7.1225 18.3406C7.43062 19.0313 8.24062 19.3413 8.93125 19.0331C9.62188 18.725 9.93188 17.915 9.62375 17.2244L8.79531 15.3678M3.8125 11H7.25L13.5 15.375V1L7.25 5.375H3.8125C2.25938 5.375 1 6.63438 1 8.1875C1 9.74062 2.25938 11 3.8125 11ZM4.75 5.375V11"
                stroke="#0078FB"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div class="h-full flex-1 text-blues-40">
            <p class="font-bold">
              Make the most of your Hypefury experience by selecting a yearly plan:
            </p>
            <ul class="m-0 ml-6">
              <li>12 months for the price of 10 (2 whole months for FREE)</li>
              <li>Price hike immunity for at least 1 year</li>
            </ul>
            <new-button @click="openPlansModalWithYearlyPlans" class="mt-3"
              >Upgrade to Yearly plan</new-button
            >
          </div>
          <div class="flex-initial">
            <inline-svg
              class="h-5 w-5 cursor-pointer text-blues-40"
              @click="showPlanNotification = false"
              src="/img/icons/close.svg"
            />
          </div>
        </div> -->
        <div
          @click="showBilling = true"
          class="border relative flex w-full cursor-pointer items-center justify-between overflow-hidden rounded-lg border-ds-outline-secondary bg-ds-foreground p-4"
        >
          <div class="flex h-full flex-1 flex-col justify-start gap-4">
            <div class="flex items-center gap-4">
              <p class="text-xl font-extrabold">{{ userPlan }} plan</p>
              <div
                class="flex-initial rounded-full px-3 py-1 text-center"
                :style="getSubscriptionStatusConfig(userPlan)"
              >
                <p>{{ getSubscriptionStatusConfig().title }}</p>
              </div>
            </div>
            <p v-if="nextPaymentDetailsText">{{ nextPaymentDetailsText }}</p>
          </div>
          <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.4 19.8001L18.2 15L13.4 10.2001M15 1.66671C22.3638 1.66671 28.3334 7.63624 28.3334 15C28.3334 22.3638 22.3638 28.3334 15 28.3334C7.63622 28.3334 1.66669 22.3638 1.66669 15C1.66669 7.63624 7.63622 1.6667 15 1.66671Z"
              stroke="#0078FB"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <img src="/logo.png" alt="logo" class="-top-21 absolute -right-11 w-241 opacity-10" />
        </div>
        <!-- TODO: Video generator -->
        <!-- <div class="border-t w-full border-ds-outline-secondary"></div>
        <div
          class="border flex w-full flex-col items-start justify-center space-y-4 rounded-lg border-ds-outline-secondary p-4"
        >
          <p class="text-lg font-bold">Video generator credits</p>
          <p>
            Your {{ userPlan }} plan allows you to convert {{ videosLeft }} tweets to videos per
            month.
          </p>
          <div
            class="flex w-full flex-wrap items-center justify-between gap-2 rounded-lg bg-ds-button-secondary-background p-4"
          >
            <div class="flex items-center gap-2">
              <svg
                width="19"
                height="16"
                viewBox="0 0 19 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 14.3333H9.33333H4.33333C3.44928 14.3333 2.60143 13.9821 1.97631 13.357C1.35119 12.7319 1 11.8841 1 11V4.33333C1 3.44928 1.35119 2.60143 1.97631 1.97631C2.60143 1.35119 3.44928 1 4.33333 1H14.3333C15.2174 1 16.0652 1.35119 16.6904 1.97631C17.3155 2.60143 17.6667 3.44928 17.6667 4.33333V7.66667V9M11 11.5C11.4596 11.5 11.9148 11.4095 12.3394 11.2336C12.764 11.0577 13.1499 10.7999 13.4749 10.4749C13.7999 10.1499 14.0577 9.76403 14.2336 9.33939C14.4095 8.91475 14.5 8.45963 14.5 8C14.5 8.92826 14.8687 9.8185 15.5251 10.4749C16.1815 11.1313 17.0717 11.5 18 11.5C17.0717 11.5 16.1815 11.8687 15.5251 12.5251C14.8687 13.1815 14.5 14.0717 14.5 15C14.5 14.5404 14.4095 14.0852 14.2336 13.6606C14.0577 13.236 13.7999 12.8501 13.4749 12.5251C13.1499 12.2001 12.764 11.9423 12.3394 11.7664C11.9148 11.5905 11.4596 11.5 11 11.5ZM7.66663 5.16669L11.8333 7.66669L7.66663 10.1667V5.16669Z"
                  stroke="#0078FB"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <p class="text-blues-40">
                {{ userTweetToVideoLeft }}/{{ planTweetToVideoCount }} videos left
              </p>
            </div>
            <new-button v-if="nextPlanToUpgradeTo" @click="showPlansModal = true"
              >Upgrade to {{ nextPlanToUpgradeTo }}</new-button
            >
          </div>
        </div> -->
      </div>
      <div v-else class="flex w-full flex-col items-center gap-8">
        <div
          v-if="showPlanNotification && parityDiscountCountry && parityDiscount"
          class="flex w-full items-start justify-between gap-3 rounded-lg bg-ds-button-secondary-background p-4 md:w-591"
        >
          <div class="flex-initial">
            <svg class="h-5 w-5" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M16.25 9.125H18.75M16.1613 5.0625L18.3263 3.8125M16.1613 13.1875L18.3263 14.4375M3.8125 11L7.1225 18.3406C7.43062 19.0313 8.24062 19.3413 8.93125 19.0331C9.62188 18.725 9.93188 17.915 9.62375 17.2244L8.79531 15.3678M3.8125 11H7.25L13.5 15.375V1L7.25 5.375H3.8125C2.25938 5.375 1 6.63438 1 8.1875C1 9.74062 2.25938 11 3.8125 11ZM4.75 5.375V11"
                stroke="#0078FB"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div class="h-full flex-1 text-blues-40">
            <p class="font-bold">
              Our users from {{ parityDiscountCountry }} get a {{ parityDiscount }} discount on the
              Standard plan. It will be automatically applied when you update your subscription.
            </p>
          </div>
          <div class="flex-initial">
            <inline-svg
              class="h-5 w-5 cursor-pointer text-blues-40"
              @click="showPlanNotification = false"
              src="/img/icons/close.svg"
            />
          </div>
        </div>
        <ToggleInput :toggle="isSubscriptionTypeMonthly">
          <template slot="left">
            <p @click="changeSubscriptionType(true)" data-cy="billing-monthly">Monthly</p>
          </template>
          <template slot="right">
            <p @click="changeSubscriptionType(false)" data-cy="billing-yearly">Yearly</p>
          </template>
        </ToggleInput>
        <div
          v-if="selectedSubscriptionType"
          class="flex w-full flex-wrap items-center justify-center gap-4 p-6"
        >
          <PlanCard
            v-for="plan in plansToDisplay"
            :key="plan.name"
            :currentPlan="plan"
            :isSubscriptionTypeMonthly="isSubscriptionTypeMonthly"
            :dataCy="plan.name + '-plan'"
            @checkout="checkout($event)"
          />
        </div>
        <new-button size="lg" @click="showPlansModal = true" type="secondary"
          >Compare plans</new-button
        >
      </div>
    </div>
  </div>
</template>

<script>
  import lodash from 'lodash';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import { mapGetters, mapState } from 'vuex';
  import { HalfCircleSpinner } from 'epic-spinners';
  import SlideOver from '@/components/SlideOver.vue';
  import ToggleInput from '@/components/Inputs/ToggleInput.vue';
  import PlansModal from '@/components/Subscription/PlansModal.vue';
  import PlanCard from '@/components/Subscription/PlanCard.vue';
  import { v4 as publicIpv4 } from 'public-ip';
  import controller from '@/controller';
  import CustomerStatusMixin from './Mixins/CustomerStatusMixin.vue';
  import CustomerCountryMixin from '@/views/Mixins/CustomerCountryMixin.vue';
  import SwalModalMixin from '@/views/Mixins/SwalModalMixin.vue';
  import moment from 'moment';
  import 'moment-timezone';
  import * as config from '../config';
  import dao from '@/dao';
  import { PLANS } from '../../functions/src/util/customerStatus.cjs';
  import { wasUserCreatedBeforePlansChanges } from '@/../functions/src/util/wasUserCreatedBeforePlansChanges';

  const SUBSCRIPTION_STATUS = {
    active: {
      title: 'Active',
      color: 'white',
      'background-color': '#22c55e',
    },
    deleted: {
      title: 'Cancelled',
      color: 'white',
      'background-color': '#ef4444',
    },
    paused: {
      title: 'Paused',
      color: 'white',
      'background-color': '#3596FF',
    },
  };
  const PARITY_DISCOUNT_BY_COUNTRY = {
    AF: 40,
    AL: 40,
    AR: 50,
    DZ: 50,
    AO: 50,
    AM: 50,
    AZ: 50,
    BH: 10,
    BD: 50,
    BR: 40,
    BY: 40,
    BZ: 20,
    BJ: 20,
    BT: 30,
    BO: 50,
    BA: 50,
    BW: 50,
    BN: 20,
    BG: 40,
    BF: 50,
    BI: 50,
    KH: 40,
    CM: 40,
    CN: 40,
    CV: 40,
    CF: 50,
    CZ: 20,
    TD: 50,
    CL: 40,
    CO: 50,
    KM: 30,
    CD: 50,
    CG: 50,
    CR: 30,
    HR: 20,
    HU: 30,
    CY: 20,
    DJ: 50,
    DM: 40,
    DO: 50,
    TL: 50,
    EC: 40,
    EG: 50,
    SV: 40,
    GQ: 40,
    ER: 50,
    EE: 20,
    ET: 50,
    FJ: 40,
    GA: 50,
    GM: 50,
    GE: 40,
    GH: 50,
    GR: 20,
    GT: 30,
    GN: 50,
    GW: 50,
    HT: 40,
    HN: 40,
    ID: 40,
    IN: 40,
    IQ: 50,
    CI: 40,
    JM: 30,
    JO: 30,
    KZ: 50,
    KE: 50,
    KW: 30,
    KG: 50,
    LA: 40,
    LV: 30,
    LS: 50,
    LR: 50,
    LT: 20,
    MX: 30,
    MK: 30,
    MG: 40,
    MW: 40,
    MY: 40,
    MV: 20,
    ML: 40,
    MR: 40,
    MU: 30,
    MD: 50,
    MN: 50,
    MA: 40,
    MZ: 50,
    NA: 50,
    NP: 50,
    NE: 50,
    NG: 50,
    PK: 50,
    PL: 30,
    PS: 40,
    PA: 30,
    PY: 50,
    PE: 50,
    PH: 40,
    PT: 20,
    PR: 20,
    RO: 40,
    RW: 50,
    SA: 30,
    SN: 40,
    RS: 30,
    SL: 50,
    SI: 20,
    SS: 50,
    LK: 50,
    SR: 50,
    SZ: 50,
    TJ: 50,
    TZ: 50,
    TG: 40,
    TH: 30,
    TN: 50,
    TM: 30,
    TR: 40,
    UG: 50,
    UY: 40,
    UZ: 50,
    VU: 50,
    VN: 50,
    ZM: 50,
    ZW: 50,
    RU: 30,
    ZA: 20,
    UA: 40,
  };
  const PLANS_TO_DISPLAY = ['standard', 'premium', 'business', 'agency'];
  const MOST_PICKED_PLAN = 'premium';

  export default {
    name: 'Subscription',
    components: {
      SlideOver,
      ToggleInput,
      PlansModal,
      HalfCircleSpinner,
      PlanCard,
    },
    data() {
      return {
        showPlanNotification: true,
        showBilling: false,
        showPlansModal: false,
        isSubscriptionTypeMonthly: true,
        subscriptionDetails: null,
        isLoading: false,
        plansDetails: {},
        yearlyPlans: {},
        monthlyPlans: {},
        intervalCoupons: {},
      };
    },
    methods: {
      loadChurnkey() {
        if (this.isChurnKeyLoaded || (window.churnkey && window.churnkey.created)) return;
        const appId = lodash.get(this, 'parentUserProfile.isStripeCustomer', false)
          ? 'lr1evtkc3'
          : 'dh6ci75lv';

        !(function () {
          window.churnkey = { created: true };
          const a = document.createElement('script');
          a.src = `https://assets.churnkey.co/js/app.js?appId=${appId}`;
          a.async = true;
          const b = document.getElementsByTagName('script')[0];
          b.parentNode.insertBefore(a, b);
        })();

        this.isChurnKeyLoaded = true;
      },
      loadPaddle() {
        if (!window.Paddle) return;
        if (!config.isEnvProd) {
          window.Paddle.Environment.set('sandbox');
        }

        window.Paddle.Setup({
          vendor: config.paddleInfo.vendorId,
          eventCallback: async (data) => {
            const eventData = data.eventData;
            if (data.event === 'Checkout.PaymentComplete') {
              this.getBillingData();

              // First promoter integration
              window.fpr &&
                window.fpr('referral', {
                  email: eventData.user.email,
                  uid: eventData.user.id,
                });
            }

            if (data.event === 'Checkout.Coupon.Applied') {
              const coupon = eventData.coupon.coupon_code;
              const intervalCoupon = this.intervalCoupons[coupon];
              if (intervalCoupon) {
                const months = intervalCoupon.durationInMonths;
                const monthsText = months === 1 ? 'month' : 'months';
                this.$notify({
                  type: 'success',
                  message: `Your coupon has been applied successfully and it will be valid for ${months} ${monthsText}!`,
                });
              }
            }

            if (data.event === 'Checkout.Complete') {
              window.dataLayer = window.dataLayer || [];
              // eslint-disable-next-line no-inner-declarations, prettier/prettier, no-undef
              function gtag(){dataLayer.push(arguments)};
              gtag('event', 'purchase', {
                transaction_id: data.eventData.checkout.id,
                value: data.eventData.checkout.prices.customer.total,
                currency: data.eventData.checkout.prices.customer.currency,
              });
            }
          },
        });
      },
      async fetchCoupons() {
        try {
          this.intervalCoupons = await dao.appSettings.fetchCoupons();
        } catch (error) {
          console.error(`An error occurred while fetching coupons: ${error}`);
        }
      },
      changeSubscriptionType(value) {
        this.isSubscriptionTypeMonthly = value;
      },
      getPaymentMethodName(paymentInformation) {
        if (paymentInformation.payment_method !== 'card') {
          return paymentInformation.payment_method;
        } else {
          return paymentInformation.card_type.replace(/_/g, ' ');
        }
      },
      getSubscriptionStatusConfig() {
        return (
          SUBSCRIPTION_STATUS[this.parentUserProfile.subscriptionStatus] ?? {
            title: 'Trial',
            color: 'black',
            'background-color': 'yellow',
          }
        );
      },
      closeBilling() {
        const billingSlideOver = this.$refs.billingSlideOver;
        if (billingSlideOver) {
          billingSlideOver.dismiss();
        }
      },
      async getUserSubscriptionDetails() {
        try {
          const customerIp = await publicIpv4();
          const subscriptionDetails = await controller.getUserSubscriptionDetails(
            this.currentUser,
            customerIp,
          );
          this.subscriptionDetails = subscriptionDetails;
        } catch (error) {
          console.error(`An error occurred while trying to fetch the subscription details:`, error);
        }
      },
      async getPlansDetails() {
        try {
          const customerIp = await publicIpv4();
          const plansDetails = await controller.getPlansPrices(this.currentUser, customerIp);
          const monthly = {};
          const yearly = {};

          plansDetails.forEach((plan) => {
            const [title, type] = plan.product_title.split(' ');
            if (type === 'monthly') {
              monthly[title] = {
                ...plan,
                displayPrice: `${plan.price.net} ${plan.currency}`,
              };
            } else if (type === 'yearly') {
              yearly[title] = {
                ...plan,
                displayPrice: `${plan.price.net} ${plan.currency}`,
              };
            }
          });

          this.yearlyPlans = yearly;
          this.monthlyPlans = monthly;
        } catch (error) {
          console.error(`An error occurred while trying to fetch the plans details:`, error);
        }
      },
      async getBillingData() {
        try {
          await this.getPlansDetails();
          await this.getUserSubscriptionDetails();
        } catch (error) {
          console.error(`An error has occurred when fetching billing data: ${error}`);
        }
      },
      getProductId(plan) {
        if (this.isSubscriptionTypeMonthly) {
          return config.paddleInfo.plans[`${plan}Monthly`];
        }

        return config.paddleInfo.plans[`${plan}Yearly`];
      },
      async createParityPayLink(productId) {
        const discountPercentage = PARITY_DISCOUNT_BY_COUNTRY[this.countryCode];
        if (!discountPercentage) {
          return;
        }

        let plan = lodash.find(
          this.selectedSubscriptionType,
          (element) => element?.product_id === Number(productId),
        );

        // Only apply discount to standard plans
        if (!plan.product_title.toLowerCase().includes('standard')) {
          return;
        }

        const currency = plan.currency;
        const planPrice = plan.price.net;
        const discount = Number(`0.${discountPercentage}`);
        const finalPrice = planPrice - planPrice * discount;
        const discountedPrice = `${currency}:${finalPrice.toFixed(2)}`;
        const defaultPrice = `${plan.default_price.currency}:${plan.default_price.price.net}`;

        const paylinkSettings = {
          country: this.countryCode,
          currency,
          product_id: productId,
          recurring_price: discountedPrice,
          price: discountedPrice,
          default_price: defaultPrice,
          custom_message: `Discounted price with ${discountPercentage}% OFF for users in ${this.countryName}.`,
          passthrough: JSON.stringify({ userId: this.userProfile.uid }),
        };

        try {
          const payLink = await controller.generateParityPayLink(this.currentUser, paylinkSettings);
          return payLink;
        } catch (error) {
          console.error(`An error occurred while generating parity pay link: ${error}`);
        }
      },
      async checkout(plan) {
        const camelCasePlan = plan
          .toLowerCase()
          .split(' ')
          .map((word, index) => (index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)))
          .join('');
        const lowerCasePlan = plan.toLowerCase().split(' ').join('_');
        const product = this.getProductId(camelCasePlan);

        const userQualifiesForParityDiscount = Boolean(
          PARITY_DISCOUNT_BY_COUNTRY[this.countryCode],
        );
        const doesPlanSupportParityDiscount = this.PLANS[lowerCasePlan].isParityDiscountAllowed;

        let parityPayLink = null;
        if (doesPlanSupportParityDiscount && userQualifiesForParityDiscount) {
          try {
            parityPayLink = await this.createParityPayLink(product);
          } catch (error) {
            console.error(error);
            this.swalModal({
              title: 'Sorry',
              text: 'An error occurred while trying to process your discounted checkout. Please try again later or contact support.',
              type: 'error',
            });
          }
        }

        if (
          !this.isSubscriptionActive ||
          this.parentUserProfile.subscriptionStatus.toLowerCase() === 'cancelled' ||
          this.parentUserProfile.subscriptionStatus.toLowerCase() === 'deleted'
        ) {
          const theme = localStorage.getItem('theme');
          window.Paddle.Checkout.open({
            ...(parityPayLink
              ? {
                  override: parityPayLink,
                  customData: JSON.stringify({
                    parityDiscount: {
                      country: this.countryCode,
                      percentageOff: this.parityDiscount.replace('%', ''),
                    },
                  }),
                }
              : {}),
            ...(!parityPayLink
              ? {
                  country: this.countryCode,
                  email: this.parentUserProfile.email,
                  product,
                  passthrough: JSON.stringify({ userId: this.userProfile.uid }),
                }
              : {}),
            displayModeTheme: !['dark', 'light'].includes(theme) ? 'light' : theme,
            successCallback: () => {
              this.fetchAllData();
            },
          });
        } else {
          const title = `Updating subscription to ${plan} ${
            this.isSubscriptionTypeMonthly ? 'monthly' : 'yearly'
          }`;

          const currentPlanPriceInfo = this.subscriptionDetails.plan_prices;
          const currentPlanNet = currentPlanPriceInfo.subscription.price.net;
          const currentPlanTax = currentPlanPriceInfo.subscription.price.tax;
          const currentPlanPrice = `${currentPlanNet} ${currentPlanPriceInfo.currency} + VAT ${currentPlanTax} ${currentPlanPriceInfo.currency}`;

          // If it's moving from monthly to yearly, remove modifiers
          const shouldKeepModifiers =
            this.isSubscriptionTypeMonthly && currentPlanPriceInfo.subscription.interval === 'month'
              ? false
              : true;

          const newBillingInterval = this.isSubscriptionTypeMonthly ? 'month' : 'year';
          const currentBillingInterval = currentPlanPriceInfo.subscription.interval;
          const newPlan = lodash.find(
            this.selectedSubscriptionType,
            (element) => element?.product_id === Number(product),
          );
          const currency = newPlan.currency;
          let newPlanNetPrice = newPlan.price.net;
          let newPlanTaxPrice = newPlan.price.tax;

          const parityDiscount = lodash.get(
            this.subscriptionDetails,
            'custom_data.parityDiscount.percentageOff',
            null,
          );
          let discountMessage = '';
          let newPriceInfo = null;

          // Only apply discount to standard plans
          if (
            parityDiscount &&
            lodash.get(newPlan, 'product_title', '').toLowerCase().includes('standard')
          ) {
            const discount = Number(parityDiscount);
            const amountOff = (discount / 100) * newPlanNetPrice;
            const taxPercentage = (100 * newPlanTaxPrice) / newPlanNetPrice;
            newPlanNetPrice = (newPlanNetPrice - amountOff).toFixed(2);
            newPlanTaxPrice = ((taxPercentage / 100) * newPlanNetPrice).toFixed(2);
            discountMessage = `<span class='bg-green-500 p-2 rounded-lg'><b>Your ${discount}% discount for ${this.parityDiscountCountry} has been applied.</b></span><br/><br/>`;
            newPriceInfo = {
              recurring_price: newPlanNetPrice,
              quantity: 1,
              currency,
            };
          }

          this.swalModal({
            title,
            html: `${discountMessage}You will be billed <b>${newPlanNetPrice} ${currency} + VAT ${newPlanTaxPrice} ${currency} per ${newBillingInterval}</b> instead of <b>${currentPlanPrice} per ${currentBillingInterval}</b>.`,
            type: 'info',
            confirmButtonText: 'Yes, I want to update my subscription',
            cancelButtonText: 'No, thanks',
            showCancelButton: true,
            allowOutsideClick: false,
            preConfirm: async () => {
              Swal.getConfirmButton().textContent = 'Updating subscription...';
              Swal.getCloseButton().disabled = true;
              await this.updateUserSubscription(product, newPriceInfo, shouldKeepModifiers);
              return true;
            },
          });
        }
      },
      async cancelSubscription() {
        this.closeBilling();
        await this.churnkey();
      },
      waitForElm(selector) {
        return new Promise((resolve) => {
          if (document.querySelector(selector)) {
            return resolve(document.querySelector(selector));
          }

          const observer = new MutationObserver(() => {
            if (document.querySelector(selector)) {
              resolve(document.querySelector(selector));
              observer.disconnect();
            }
          });

          observer.observe(document.body, {
            childList: true,
            subtree: true,
          });
        });
      },
      churnkey() {
        const self = this;
        function cancelCallback() {
          return async function (resolve, reject) {
            try {
              self.isLoading = true;
              await controller.cancelUserSubscription(self.currentUser);
              resolve({
                message: 'Your subscription has been cancelled. You will not be billed again.',
              });
            } catch (e) {
              console.error(e);
              reject({ message: 'Failed to cancel subscription. Please contact support.' });
            }
            self.isLoading = false;
            self.getBillingData();
          };
        }
        function pauseCallback(durationInfo) {
          return async function (resolve, reject) {
            const durationInMonths = durationInfo.pauseDuration;
            try {
              await controller.pauseSubscription(self.currentUser, durationInMonths);
              resolve({
                message: `Your subscription is now paused for ${durationInMonths} months.`,
              });
            } catch (e) {
              console.error(e);
              reject({
                message: 'Failed to pause subscription. Please contact support.',
              });
            }
          };
        }
        function discountCallback() {
          return async function (resolve, reject) {
            try {
              self.isLoading = true;
              const planDetails = self.subscriptionDetails.plan_prices;
              const hasParityDiscount = Boolean(
                lodash.get(
                  self.subscriptionDetails,
                  'custom_data.parityDiscount.percentageOff',
                  false,
                ),
              );
              const price = hasParityDiscount
                ? self.subscriptionDetails.next_payment.amount
                : planDetails.price.net;

              const discountData = {
                nextPaymentDate: self.subscriptionDetails.next_payment.date,
                price,
              };

              if (self.isStripeCustomer) {
                await controller.applyStripeCancellationDiscount(self.currentUser);
              } else {
                await controller.applyCancellationDiscount(self.currentUser, discountData);
              }

              const interval = self.parentUserProfile.subscriptionInterval;

              const amountOff = interval === 'month' ? '50%' : '20%';

              resolve({
                message: `Your discount for ${amountOff} off ${
                  interval === 'month' ? 'for 3 months' : ''
                } has been applied. Enjoy`,
              });
            } catch (e) {
              console.error(e);
              reject({ message: 'Failed to apply discount. Please contact support.' });
            } finally {
              self.isLoading = false;
              self.getBillingData();
            }
          };
        }

        return new Promise((resolve) => {
          function replaceDiscountText() {
            self.waitForElm('.ck-discount-step').then((elm) => {
              const hasParityDiscount = Boolean(
                lodash.get(
                  self.subscriptionDetails,
                  'custom_data.parityDiscount.percentageOff',
                  false,
                ),
              );
              const currentPlanPriceInfo = self.subscriptionDetails.plan_prices;
              const interval = currentPlanPriceInfo.subscription.interval;
              const price = hasParityDiscount
                ? self.subscriptionDetails.next_payment.amount
                : currentPlanPriceInfo.price.net;
              const currency = currentPlanPriceInfo.currency;
              const percentageOff = interval === 'month' ? 50 : 20;
              const currencyAmountOff = -(percentageOff / 100) * price;

              const div = elm.getElementsByTagName('div')[1];
              div.innerHTML = `${percentageOff}% off <br /> (${currencyAmountOff.toFixed(
                2,
              )} ${currency}) ${
                interval === 'month' ? 'for 3 months starting' : ''
              } in your next renewal.`;
            });
          }
          self.waitForElm('.ck-step-footer').then((elm) => {
            const buttons = Array.from(elm.getElementsByTagName('button'));
            buttons.forEach((button) => {
              button.addEventListener('click', replaceDiscountText);
            });
          });

          const appId = self.isStripeCustomer ? 'lr1evtkc3' : 'dh6ci75lv';

          window.churnkey.init('show', {
            ...(self.isStripeCustomer
              ? { customerId: self.parentUserProfile.subscriptionCustomerId }
              : {}),
            ...(!self.isStripeCustomer
              ? { subscriptionId: self.parentUserProfile.subscriptionId }
              : {}),
            authHash: self.churnkeyHMAC,
            appId,
            mode: config.isEnvProd ? 'live' : 'test',
            provider: self.isStripeCustomer ? 'stripe' : 'paddle',
            handleSupportRequest: () => {
              window.$crisp.push(['do', 'chat:open']);
              window.churnkey.hide();
              resolve(null);
            },
            handleCancel: (_, cancellationReason) => {
              resolve(cancellationReason);
              const message = 'Your subscription has been cancelled. You will not be billed again.';
              if (self.isStripeCustomer) {
                return { message };
              } else {
                return new Promise(cancelCallback());
              }
            },
            handlePause: (_, durationInfo) => {
              resolve(null);
              return new Promise(pauseCallback(durationInfo));
            },
            handleDiscount: () => {
              return new Promise(discountCallback());
            },
            handlePlanChange: () => {
              resolve(null);
            },
          });
        });
      },
      async fetchAllData() {
        this.isLoading = true;

        await Promise.allSettled([
          this.getCustomerCountry(),
          this.getBillingData(),
          this.fetchCoupons(),
        ]);

        this.isLoading = false;
      },
      openPlansModal() {
        this.closeBilling();
        this.showPlansModal = true;
      },
      updatePaymentMethod() {
        const theme = localStorage.getItem('theme');
        window.Paddle.Checkout.open({
          override: this.subscriptionDetails.update_url,
          displayModeTheme: !['dark', 'light'].includes(theme) ? 'light' : theme,
          successCallback: () => {
            this.getBillingData();
          },
        });
      },
      async updateUserSubscription(planId, newPriceInfo = null, keepModifiers = true) {
        try {
          this.isLoading = true;
          await controller.updateUserSubscription(
            this.currentUser,
            planId,
            newPriceInfo,
            keepModifiers,
          );
          this.$notify({
            type: 'success',
            message: 'Your subscription has been updated successfully!',
          });
        } catch (error) {
          console.error(error);
          this.swalModal({
            title: 'Sorry',
            text: 'An error occurred while trying to update your subscription. Please try again later or contact support.',
            type: 'error',
          });
        } finally {
          this.isLoading = false;
          this.isUpdatingSubscription = false;
          await this.getBillingData();
          this.$forceUpdate();
        }
      },
      openPlansModalFromQuery() {
        if (this.$route.query.upgradeToYearly === 'true') this.showPlansModal = true;
      },
      openPlansModalWithYearlyPlans() {
        this.openPlansModal();
        this.isSubscriptionTypeMonthly = false;
      },
    },
    computed: {
      ...mapGetters({
        currentUser: 'getCurrentUser',
        userProfile: 'getUserProfile',
        parentUserProfile: 'getParentUserProfile',
      }),
      ...mapState(['userProfiles', 'isPaddleReady', 'churnkeyHMAC']),
      isSubscriptionActive() {
        return Boolean(
          this.parentUserProfile &&
            this.parentUserProfile.subscriptionCustomerId &&
            this.parentUserProfile.subscriptionId,
        );
      },
      shouldShowBasicPlan() {
        return false;
        /* - User created before plans changes
            - Free: Show basic plan on billing UI 8 days after account creation (7 days trial + 1 day)
            - Trial: Show basic plan on Cancellations and billing UI 1 day after Trial is over
            - Subscribed: Show Basic plan on Cancellations UI, Show it on Billing UI on expiration
           - User created after plans changes
            - Free: Don't show Basic plan
            - Trial: Show Basic plan on Cancellations UI
            - Subscribed: Show Basic plan on Cancellations UI, Show it on Billing UI on expiration */
        // if (wasUserCreatedBeforePlansChanges(this.parentUserProfile.created_at)) {
        //   const timePassedSinceAccountCreation = moment().diff(
        //     moment(this.parentUserProfile.created_at.toDate()),
        //     'days',
        //   );
        //   return (
        //     (this.parentUserProfile.customerStatus === 'none' ||
        //       this.parentUserProfile.subscriptionStatus === 'past_due') &&
        //     timePassedSinceAccountCreation >= 8
        //   );
        // }

        // return (
        //   this.parentUserProfile.subscriptionStatus === 'past_due' &&
        //   // Only show basic plan on billing UI if the user has been on an active subscription. (Skip trial)
        //   this.parentUserProfile.previousSubscriptionStatus === 'active'
        // );
      },
      selectedSubscriptionType() {
        if (this.isSubscriptionTypeMonthly) {
          return this.monthlyPlans;
        }
        return this.yearlyPlans;
      },
      nextPaymentDetailsText() {
        if (!this.subscriptionDetails || this.parentUserProfile.subscriptionEndDate) return null;
        const nextPaymentDetails = this.subscriptionDetails.next_payment;
        if (!nextPaymentDetails) return null;
        const date = moment(nextPaymentDetails.date).format('MMM Do, YYYY');
        const hasTax = Boolean(this.subscriptionDetails.plan_prices.price.tax);
        return `Next payment: ${nextPaymentDetails.amount} ${nextPaymentDetails.currency} ${
          hasTax ? '(inc. VAT)' : ''
        } due on ${date}`;
      },
      showCancelSubscriptionButton() {
        return this.parentUserProfile && this.parentUserProfile.subscriptionStatus !== 'deleted';
      },
      // TODO: implement videos left according to user plan
      planTweetToVideoCount() {
        return 9;
      },
      userTweetToVideoLeft() {
        return 6;
      },
      nextPlanToUpgradeTo() {
        switch (this.userPlan) {
          case 'Starter':
            return 'Creator';
          case 'Creator':
            return 'Business';
          case 'Business':
            return 'Agency';
          default:
            break;
        }
        return null;
      },
      videosLeft() {
        return 30 - this.userProfile.reels.renderCount;
      },
      parityDiscountInExistingSubscription() {
        if (!this.isSubscriptionActive) return null;

        return lodash.get(this.subscriptionDetails, 'custom_data.parityDiscount', null);
      },
      parityDiscountCountry() {
        if (this.parityDiscountInExistingSubscription) {
          return this.getCountryName(this.parityDiscountInExistingSubscription.country);
        } else {
          return this.countryName;
        }
      },
      parityDiscount() {
        if (this.parityDiscountInExistingSubscription) {
          return `${this.parityDiscountInExistingSubscription.percentageOff}%`;
        }

        if (!this.countryCode) return null;

        if (!PARITY_DISCOUNT_BY_COUNTRY[this.countryCode]) return null;

        return `${PARITY_DISCOUNT_BY_COUNTRY[this.countryCode]}%`;
      },
      isStripeCustomer() {
        return lodash.get(this, 'parentUserProfile.isStripeCustomer', false);
      },
      showUpgradeToYearlyPlanNotification() {
        return (
          this.subscriptionDetails &&
          this.subscriptionDetails.plan_prices.subscription.interval === 'month' &&
          this.showPlanNotification
        );
      },
      computedShowPlansModal() {
        return !this.isLoading && this.showPlansModal;
      },
      planNames() {
        return [this.shouldShowBasicPlan ? 'basic' : null, ...PLANS_TO_DISPLAY].filter(Boolean);
      },
      plansToDisplay() {
        const plansArray = Object.entries(PLANS)
          .map(([key, value]) => {
            const capitalizedName = lodash.capitalize(key);
            const prices = {
              monthly: this.monthlyPlans[capitalizedName],
              yearly: this.yearlyPlans[capitalizedName],
            };

            return { name: key, prices, ...value, isMostPicked: key === MOST_PICKED_PLAN };
          })
          .filter((plan) => this.planNames.includes(plan.name));

        return plansArray;
      },
    },
    watch: {
      isPaddleReady(val) {
        if (val) {
          this.loadPaddle();
          this.getBillingData();
        }
      },
      churnkeyHMAC(val) {
        if (val) {
          this.loadChurnkey();
        }
      },
      showUpgradeToYearlyPlanNotification(value) {
        if (value) this.isSubscriptionTypeMonthly = false;
      },
    },
    mounted() {
      this.fetchAllData();
      this.loadChurnkey();
      this.openPlansModalFromQuery();
    },
    mixins: [CustomerStatusMixin, CustomerCountryMixin, SwalModalMixin],
  };
</script>
