<script>
  import { h } from 'vue';

  export default {
    name: 'Inline',
    props: {
      args: {
        type: Array,
        default: () => [],
      },
    },
    render() {
      return h(
        'span',
        { class: ['flex', 'items-center', 'gap-2'] },
        this.args.map((arg) => {
          if (typeof arg === 'string') return h('p', {}, arg);
          return h(arg.component, { class: arg.class, ...arg.props });
        }),
      );
    },
  };
</script>
