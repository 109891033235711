<template>
  <slide-over
    ref="plansSlideOver"
    @close="this.dismiss"
    :enableMobileBottomToTopTranslation="true"
    :open="showPlansModal"
    containerClasses="h-screen w-screen bg-inherit flex justify-center items-end md:items-center"
  >
    <div
      class="relative w-full overflow-y-auto rounded-lg bg-ds-foreground pb-5 md:w-auto md:pb-8"
      style="max-height: 670px"
    >
      <p class="pl-4 pt-4 text-2xl font-bold md:pl-8 md:pt-8">Plans</p>
      <div class="hidden items-center gap-3 pl-5 md:flex md:pl-8">
        <p class="text-lg text-gray-80 dark-mode:text-dark-mode-50">Billing</p>
        <toggle-input :toggle="isSubscriptionTypeMonthly">
          <template slot="left">
            <p @click="changeSubscriptionType(true)">Monthly</p>
          </template>
          <template slot="right">
            <p @click="changeSubscriptionType(false)">Yearly</p>
          </template>
        </toggle-input>
      </div>
      <inline-svg
        @click="closePlansModal"
        class="absolute right-4 top-4 h-5 w-5 cursor-pointer text-blues-40 md:right-8 md:top-8"
        src="/img/icons/close.svg"
      />
      <div class="w-full px-4 md:px-8" :style="gridColumnsStyle">
        <div class="sticky top-0 col-span-2 hidden bg-ds-foreground lg:block"></div>
        <header-cell v-for="plan in capitalizedPlanNames" :key="plan">
          <div>
            <p class="text-center text-sm font-medium md:text-left md:text-xl">
              {{ getPlanName(plan) }}
            </p>
            <p
              v-if="selectedSubscriptionType[plan]"
              class="hidden text-2xl font-bold text-blues-50 md:block"
            >
              {{ selectedSubscriptionType[plan].displayPrice }}/{{
                isSubscriptionTypeMonthly ? 'month' : 'year'
              }}
            </p>
            <p class="hidden text-sm text-gray-50 md:block">VAT Excl.</p>
          </div>
          <select-plan-btn
            v-on:checkout="checkout($event)"
            size="sm"
            :isSubscriptionTypeMonthly="isSubscriptionTypeMonthly"
            :plan="plan"
          />
        </header-cell>
        <template v-for="(plan, planIndex) in this.computedPlansData">
          <full-row :key="planIndex + Math.random() * 100_100">
            <p class="text-xl font-bold">{{ plan.header }}</p>
          </full-row>
          <template v-for="(feature, titleIndex) in plan.features">
            <row-title-cell :key="titleIndex + Math.random() * 100_100">
              <inline :args="feature.title" />
            </row-title-cell>
            <row-cell
              v-for="(value, valueIndex) in feature.values"
              :key="valueIndex + Math.random() * 100_100"
              :class="{
                'border-l mlg:border-l-0': valueIndex === 0,
                'border-r': valueIndex === feature.values.length - 1,
              }"
            >
              <plan-check-box v-if="typeof value === 'boolean'" :is-checked="value" />
              <p v-else>{{ value }}</p>
            </row-cell>
          </template>
        </template>
      </div>
    </div>
  </slide-over>
</template>

<script>
  import lodash from 'lodash';
  import Inline from './Inline.vue';
  import SlideOver from '@/components/SlideOver.vue';
  import MobileMixin from '@/views/Mixins/MobileMixin.vue';
  import ToggleInput from '@/components/Inputs/ToggleInput.vue';
  import FullRow from '@/components/Subscription/Table/FullRow.vue';
  import RowCell from '@/components/Subscription/Table/RowCell.vue';
  import PlanCheckBox from '@/components/Subscription/PlanCheckBox.vue';
  import { PLANS } from '../../../functions/src/util/customerStatus.cjs';
  import HeaderCell from '@/components/Subscription/Table/HeaderCell.vue';
  import SelectPlanBtn from '@/components/Subscription/SelectPlanBtn.vue';
  import RowTitleCell from '@/components/Subscription/Table/RowTitleCell.vue';
  import CustomerStatusMixin from '../../views/Mixins/CustomerStatusMixin.vue';

  const STATIC_PLANS_DATA = {
    basic: {
      viralTweets: true,
      viralThreadHooks: true,
      tweetTemplates: true,
      exampleQuestions: true,
      yourBestTweets: true,
      linkedInPersonal: true,
      linkedInBusiness: false,
      instagram: true,
      facebookPagesAnfGroups: true,
      viralPostInspiration: true,
      threadPreview: true,
      tweetBooster: true,
      autoplugs: true,
      engagementBuilderImport: true,
      statistics: true,
      delayThreadedTweets: true,
      scheduleReplies: true,
      csvUpload: true,
      AutoRepostBestTweets: true,
      AutoRepostTweetsToInstagram: true,
      AutoRepostTweetsToFacebook: true,
      autoDms: true,
      recurrentPosts: true,
      importTweets: 'weekly',
      analytics: 'Up to 7 days',
      scheduleRetweetsForOtherPeople: true,
      basicSupport: true,
      premiumEmailSupport: false,
      liveChat: false,
      weekEndSupport: false,
      analyticsStatistics: true,
      publicAnalytics: false,
      flowerGrowth: false,
      filterTopTweets: false,
    },
    standard: {
      viralTweets: true,
      viralThreadHooks: true,
      tweetTemplates: true,
      exampleQuestions: true,
      yourBestTweets: true,
      linkedInPersonal: true,
      linkedInBusiness: false,
      instagram: true,
      facebookPagesAnfGroups: true,
      viralPostInspiration: true,
      threadPreview: true,
      tweetBooster: true,
      autoplugs: true,
      engagementBuilderImport: true,
      statistics: true,
      delayThreadedTweets: true,
      scheduleReplies: true,
      csvUpload: true,
      AutoRepostBestTweets: true,
      AutoRepostTweetsToInstagram: true,
      AutoRepostTweetsToFacebook: true,
      autoDms: true,
      recurrentPosts: true,
      importTweets: 'weekly',
      analytics: 'Up to 7 days',
      scheduleRetweetsForOtherPeople: true,
      basicSupport: true,
      premiumEmailSupport: false,
      liveChat: false,
      weekEndSupport: false,
      analyticsStatistics: true,
      publicAnalytics: false,
      flowerGrowth: false,
      filterTopTweets: false,
    },
    premium: {
      viralTweets: true,
      viralThreadHooks: true,
      tweetTemplates: true,
      exampleQuestions: true,
      yourBestTweets: true,
      linkedInPersonal: true,
      linkedInBusiness: true,
      instagram: true,
      facebookPagesAnfGroups: true,
      viralPostInspiration: true,
      threadPreview: true,
      tweetBooster: true,
      autoplugs: true,
      engagementBuilderImport: true,
      statistics: true,
      delayThreadedTweets: true,
      scheduleReplies: true,
      csvUpload: true,
      AutoRepostBestTweets: true,
      AutoRepostTweetsToInstagram: true,
      AutoRepostTweetsToFacebook: true,
      autoDms: true,
      recurrentPosts: true,
      importTweets: 'hourly',
      analytics: 'Unlimited',
      scheduleRetweetsForOtherPeople: true,
      basicSupport: false,
      premiumEmailSupport: true,
      liveChat: true,
      weekEndSupport: false,
      analyticsStatistics: true,
      publicAnalytics: true,
      flowerGrowth: true,
      filterTopTweets: true,
    },
    business: {
      viralTweets: true,
      viralThreadHooks: true,
      tweetTemplates: true,
      exampleQuestions: true,
      yourBestTweets: true,
      linkedInPersonal: true,
      linkedInBusiness: true,
      instagram: true,
      facebookPagesAnfGroups: true,
      viralPostInspiration: true,
      threadPreview: true,
      tweetBooster: true,
      autoplugs: true,
      engagementBuilderImport: true,
      statistics: true,
      delayThreadedTweets: true,
      scheduleReplies: true,
      csvUpload: true,
      AutoRepostBestTweets: true,
      AutoRepostTweetsToInstagram: true,
      AutoRepostTweetsToFacebook: true,
      autoDms: true,
      recurrentPosts: true,
      importTweets: 'hourly',
      analytics: 'Unlimited',
      scheduleRetweetsForOtherPeople: true,
      basicSupport: false,
      premiumEmailSupport: true,
      liveChat: true,
      weekEndSupport: true,
      analyticsStatistics: true,
      publicAnalytics: true,
      flowerGrowth: true,
      filterTopTweets: true,
    },
    agency: {
      viralTweets: true,
      viralThreadHooks: true,
      tweetTemplates: true,
      exampleQuestions: true,
      yourBestTweets: true,
      linkedInPersonal: true,
      linkedInBusiness: true,
      instagram: true,
      facebookPagesAnfGroups: true,
      viralPostInspiration: true,
      threadPreview: true,
      tweetBooster: true,
      autoplugs: true,
      engagementBuilderImport: true,
      statistics: true,
      delayThreadedTweets: true,
      scheduleReplies: true,
      csvUpload: true,
      AutoRepostBestTweets: true,
      AutoRepostTweetsToInstagram: true,
      AutoRepostTweetsToFacebook: true,
      autoDms: true,
      recurrentPosts: true,
      importTweets: 'hourly',
      analytics: 'Unlimited',
      scheduleRetweetsForOtherPeople: true,
      basicSupport: false,
      premiumEmailSupport: true,
      liveChat: true,
      weekEndSupport: true,
      analyticsStatistics: true,
      publicAnalytics: true,
      flowerGrowth: true,
      filterTopTweets: true,
    },
  };

  export default {
    props: {
      planNames: {
        type: Array,
        default: () => [],
      },
      showPlansModal: {
        type: Boolean,
        required: true,
      },
      isSubscriptionTypeMonthly: {
        type: Boolean,
        required: true,
      },
      selectedSubscriptionType: {
        type: Object,
        required: true,
      },
      shouldShowBasicPlan: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      computedPlansData() {
        const PLANS_DATA = [
          {
            header: 'Inspiration',
            features: [
              {
                title: ['Viral tweets from 14 niches'],
                values: this.planNames.map((plan) => STATIC_PLANS_DATA[plan].viralTweets),
              },
              {
                title: ['Viral thread hooks'],
                values: this.planNames.map((plan) => STATIC_PLANS_DATA[plan].viralThreadHooks),
              },
              {
                title: ['Tweet templates'],
                values: this.planNames.map((plan) => STATIC_PLANS_DATA[plan].tweetTemplates),
              },
              {
                title: ['1000+ example questions'],
                values: this.planNames.map((plan) => STATIC_PLANS_DATA[plan].exampleQuestions),
              },
              {
                title: ['Your best tweets'],
                values: this.planNames.map((plan) => STATIC_PLANS_DATA[plan].yourBestTweets),
              },
            ],
          },
          {
            header: 'Social media platforms',
            features: [
              {
                title: [
                  'Connected',
                  {
                    component: 'inline-svg',
                    class: ['h-3', 'w-3'],
                    props: { src: 'img/icons/new/twitter.svg' },
                  },
                  'accounts',
                ],
                values: this.planNames.map((plan) => this.getAdditionalAccount(plan)),
              },
              {
                title: [
                  'Managed',
                  {
                    component: 'inline-svg',
                    class: ['h-3', 'w-3'],
                    props: { src: 'img/icons/new/twitter.svg' },
                  },
                  'accounts',
                ],
                values: this.planNames.map((plan) => this.getManagedAccounts(plan)),
              },
              {
                title: ['Linkedin personal'],
                values: this.planNames.map((plan) => STATIC_PLANS_DATA[plan].linkedInPersonal),
              },
              {
                title: ['Linkedin business'],
                values: this.planNames.map((plan) => STATIC_PLANS_DATA[plan].linkedInBusiness),
              },
              {
                title: ['Instagram'],
                values: this.planNames.map((plan) => STATIC_PLANS_DATA[plan].instagram),
              },
              {
                title: ['Facebook Pages & Groups'],
                values: this.planNames.map(
                  (plan) => STATIC_PLANS_DATA[plan].facebookPagesAnfGroups,
                ),
              },
            ],
          },
          {
            header: 'Features',
            features: [
              {
                title: ['Schedule posts up to...'],
                values: this.planNames.map((plan) => this.getScheduling(plan)),
              },
              {
                title: ['Viral post inspiration'],
                values: this.planNames.map((plan) => STATIC_PLANS_DATA[plan].viralPostInspiration),
              },
              {
                title: ['Thread preview'],
                values: this.planNames.map((plan) => STATIC_PLANS_DATA[plan].threadPreview),
              },
              {
                title: ['Tweet booster'],
                values: this.planNames.map((plan) => STATIC_PLANS_DATA[plan].tweetBooster),
              },
              {
                title: ['Autoplugs'],
                values: this.planNames.map((plan) => STATIC_PLANS_DATA[plan].autoplugs),
              },
              {
                title: ['Save drafts'],
                values: this.planNames.map((plan) => PLANS[plan].limits.drafts),
              },
              {
                title: ['Engagement builder: watched users'],
                values: this.planNames.map((plan) => PLANS[plan].limits.feed.watchedUsers),
              },
              {
                title: ['Engagement builder: watched search terms'],
                values: this.planNames.map((plan) => PLANS[plan].limits.feed.watchedSearchTerms),
              },
              {
                title: [
                  'Engagement builder: Import',
                  {
                    component: 'inline-svg',
                    class: ['h-3', 'w-3'],
                    props: { src: 'img/icons/new/twitter.svg' },
                  },
                  'lists',
                ],
                values: this.planNames.map(
                  (plan) => STATIC_PLANS_DATA[plan].engagementBuilderImport,
                ),
              },
              {
                title: ['Statistics (likes & retweet counts)'],
                values: this.planNames.map((plan) => STATIC_PLANS_DATA[plan].statistics),
              },
              {
                title: ['Delay threaded tweets'],
                values: this.planNames.map((plan) => STATIC_PLANS_DATA[plan].scheduleReplies),
              },
              {
                title: ['Schedule replies'],
                values: this.planNames.map((plan) => STATIC_PLANS_DATA[plan].csvUpload),
              },
              {
                title: ['CSV upload'],
                values: this.planNames.map((plan) => STATIC_PLANS_DATA[plan].AutoRepostBestTweets),
              },
              {
                title: ['Automatic reposting best tweets'],
                values: this.planNames.map(
                  (plan) => STATIC_PLANS_DATA[plan].AutoRepostTweetsToInstagram,
                ),
              },
              {
                title: ['Auto post tweets to Instagram'],
                values: this.planNames.map(
                  (plan) => STATIC_PLANS_DATA[plan].AutoRepostTweetsToFacebook,
                ),
              },
              {
                title: ['Auto post tweets to Facebook'],
                values: this.planNames.map((plan) => STATIC_PLANS_DATA[plan].autoDms),
              },
              {
                title: [
                  {
                    component: 'inline-svg',
                    class: ['h-3', 'w-3'],
                    props: { src: 'img/icons/new/twitter.svg' },
                  },
                  'AutoDMs',
                ],
                values: this.planNames.map((plan) => PLANS[plan].limits.feed.dailyAutoDMs),
              },
              {
                title: ['Recurrent posts'],
                values: this.planNames.map((plan) => STATIC_PLANS_DATA[plan].recurrentPosts),
              },
              {
                title: ['Recurrent posts categories'],
                values: this.planNames.map((plan) => PLANS[plan].limits.categories),
              },
              {
                title: ['Import all your tweets'],
                values: this.planNames.map((plan) => STATIC_PLANS_DATA[plan].importTweets),
              },
              {
                title: ['Analytics: charts and insights'],
                values: this.planNames.map((plan) => STATIC_PLANS_DATA[plan].analytics),
              },
              {
                title: ['Run Gumroad sales on Auto-pilot'],
                values: this.planNames.map((plan) => this.getWeeklySales(plan)),
              },
              {
                title: ['Schedule retweets of other people'],
                values: this.planNames.map(
                  (plan) => STATIC_PLANS_DATA[plan].scheduleRetweetsForOtherPeople,
                ),
              },
            ],
          },
          {
            header: 'Support',
            features: [
              {
                title: ['Basic support'],
                values: this.planNames.map((plan) => STATIC_PLANS_DATA[plan].basicSupport),
              },
              {
                title: ['Premium email support'],
                values: this.planNames.map((plan) => STATIC_PLANS_DATA[plan].premiumEmailSupport),
              },
              {
                title: ['Live chat support'],
                values: this.planNames.map((plan) => STATIC_PLANS_DATA[plan].liveChat),
              },
              {
                title: ['Weekend support'],
                values: this.planNames.map((plan) => STATIC_PLANS_DATA[plan].weekEndSupport),
              },
            ],
          },
          {
            header: 'Analytics',
            features: [
              {
                title: ['Statistics (like & retweets counts)'],
                values: this.planNames.map((plan) => STATIC_PLANS_DATA[plan].analyticsStatistics),
              },
              {
                title: ['Optional public analytics profile'],
                values: this.planNames.map((plan) => STATIC_PLANS_DATA[plan].publicAnalytics),
              },
              {
                title: ['Follower growth & profile clicks'],
                values: this.planNames.map((plan) => STATIC_PLANS_DATA[plan].flowerGrowth),
              },
              {
                title: ['Filter top tweets'],
                values: this.planNames.map((plan) => STATIC_PLANS_DATA[plan].filterTopTweets),
              },
            ],
          },
        ];

        return PLANS_DATA;
      },
      capitalizedPlanNames() {
        return this.planNames.map((plan) => {
          return lodash.capitalize(plan);
        });
      },
      gridColumnsStyle() {
        let cols = this.planNames.length;

        if (!this.isMobile) {
          cols = this.planNames.length + 2;
        }

        return `
          display: grid;
          grid-template-columns: repeat(${cols}, minmax(0, 1fr));
        `;
      },
    },
    methods: {
      dismiss() {
        this.$emit('close');
      },
      closePlansModal() {
        const plansSlideOver = this.$refs.plansSlideOver;
        if (plansSlideOver) {
          plansSlideOver.dismiss();
        }
      },
      checkout(value) {
        this.$emit('checkout', value);
      },
      changeSubscriptionType(value) {
        this.$emit('changeSubscriptionType', value);
      },
      getAdditionalAccount(plan) {
        return PLANS[plan].limits.additionalAccounts.new + 1;
      },
      getManagedAccounts(plan) {
        const selectedPlan = PLANS[plan];
        return selectedPlan.limits.managedAccounts ?? '';
      },
      getScheduling(plan) {
        const selectedPlan = PLANS[plan];
        return selectedPlan.limits.scheduling.value === Infinity
          ? 'Unlimited'
          : `${selectedPlan.limits.scheduling.value} ${selectedPlan.limits.scheduling.unit}`;
      },
      getWeeklySales(plan) {
        const selectedPlan = PLANS[plan];
        return selectedPlan.limits.weeklySales === Infinity
          ? 'Unlimited'
          : `${selectedPlan.limits.weeklySales} per week`;
      },
      getPlanName(plan) {
        if (plan === 'Standard') return 'Starter (Standard)';
        if (plan === 'Premium') return 'Creator (Premium)';

        return plan;
      },
    },
    components: {
      SlideOver,
      ToggleInput,
      FullRow,
      RowCell,
      PlanCheckBox,
      HeaderCell,
      RowTitleCell,
      SelectPlanBtn,
      Inline,
    },
    mixins: [CustomerStatusMixin, MobileMixin],
  };
</script>
