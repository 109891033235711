<template>
  <new-button
    @click="checkout"
    class="truncate w-full"
    :class="className"
    :size="size"
    :type="buttonConfig.type"
    :disabled="shouldDisable"
    >{{ buttonConfig.label }}</new-button
  >
</template>

<script>
  import { mapGetters } from 'vuex';
  import lodash from 'lodash';
import CustomerStatusMixin from '../../views/Mixins/CustomerStatusMixin.vue';

  export default {
    name: 'SelectPlanBtn',
    props: {
      plan: {
        type: String,
        default: 'select',
        required: true,
      },
      isSubscriptionTypeMonthly: {
        type: Boolean,
        required: true,
      },
      className: {
        type: String,
        default: '',
      },
      size: {
        type: String,
        default: 'md',
        validator(value) {
          return ['lg', 'md', 'sm'].includes(value);
        },
      },
    },
    computed: {
      ...mapGetters({ parentUserProfile: 'getParentUserProfile' }),
      isCurrentUserPlan() {
        return this.parentUserProfile.customerStatus === this.plan && this.isCurrentType;
      },
      isCurrentType() {
        if (
          this.isSubscriptionTypeMonthly &&
          this.parentUserProfile.subscriptionInterval === 'month'
        ) {
          return true;
        }

        if (
          !this.isSubscriptionTypeMonthly &&
          this.parentUserProfile.subscriptionInterval === 'year'
        ) {
          return true;
        }

        return false;
      },
      buttonConfig() {
        if (
          this.parentUserProfile.subscriptionStatus &&
          this.parentUserProfile.subscriptionStatus.toLowerCase() !== 'active' &&
          this.isCurrentUserPlan
        ) {
          return {
            type: 'primary',
            label: 'Select',
          };
        }

        if (this.isCurrentUserPlan) {
          return {
            type: 'secondary',
            label: 'Current plan',
          };
        }

        return {
          type: 'primary',
          label: 'Select',
        };
      },
      shouldDisable() {
        return (
          this.isCurrentUserPlan &&
          this.parentUserProfile.subscriptionStatus.toLowerCase() === 'active'
        );
      },
    },
    methods: {
      checkout() {
        this.$emit('checkout', this.upperCasePlan());
      },
      upperCasePlan() {
        return lodash.capitalize(this.plan);
      },
    },
    mixins: [CustomerStatusMixin],
  };
</script>
