<template>
  <div
    class="border-l border-r lg:border-b col-span-full flex items-center justify-start gap-1 border-ds-outline-secondary p-3 text-base lg:col-span-2"
  >
    <slot />
  </div>
</template>

<script>
  export default {
    name: 'RowTitleCell',
    props: {
      className: String,
      default: String(''),
    },
  };
</script>
