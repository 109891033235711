<template>
  <div :class="{ 'rounded-lg bg-blues-50 p-0.5': currentPlan.isMostPicked }">
    <p v-if="currentPlan.isMostPicked" class="my-1 text-center text-base text-white">Most picked</p>
    <div
      class="normal border space-y-6 rounded-lg border-ds-outline-primary bg-ds-background p-8"
      :class="{ 'mostPicked bg-ds-button-secondary-background': currentPlan.isMostPicked }"
      :data-cy="dataCy"
    >
      <p class="text-2xl font-bold">{{ planLabel }}</p>
      <div>
        <h1 class="text-blues-50">
          {{ planPrices }}
        </h1>
        <p class="text-lg text-gray-50">VAT Excl.</p>
      </div>
      <SelectPlanBtn
        @checkout="$emit('checkout', $event)"
        :isSubscriptionTypeMonthly="isSubscriptionTypeMonthly"
        :plan="currentPlan.name"
      />
      <div
        v-if="['standard', 'basic'].includes(currentPlan.name)"
        class="flex w-full items-center justify-items-start gap-3"
      >
        <p class="text-lg font-bold">Connect</p>
        <div
          v-for="social in socialMedia"
          :key="social.id"
          class="flex items-center justify-center rounded-full bg-ds-button-secondary-background p-1"
        >
          <inline-svg class="h-3 w-3 fill-blues-50" :src="social.icon" />
        </div>
      </div>
      <div v-else class="flex w-full items-center justify-items-start gap-3">
        <p class="text-lg font-bold">Everything in {{ planBelowCurrent.label }}. plus:</p>
      </div>
      <div class="w-full space-y-3 text-base">
        <div class="flex w-full items-center gap-3">
          <inline-svg class="h-5 w-5 stroke-blues-50" src="/img/icons/schedule-icon.svg" />
          <p v-if="currentPlan.limits.scheduling.value === Infinity">Unlimited Scheduling</p>
          <p v-else>Schedule up to {{ schedulingLimit }}</p>
        </div>
        <div class="flex w-full items-center gap-3">
          <inline-svg
            class="h-5 w-5 fill-transparent stroke-blues-50"
            src="/img/icons/new/avatar-outline.svg"
          />
          <span class="flex items-center gap-1">
            <p>{{ currentPlan.limits.additionalAccounts.new + 1 }}</p>
            <inline-svg class="h-5 w-5 stroke-blues-50" src="/img/icons/new/socials/twitter.svg" />
            <p>account{{ currentPlan.limits.additionalAccounts.new > 1 ? 's' : '' }}</p>
          </span>
        </div>
        <template v-if="['standard', 'basic'].includes(currentPlan.name)">
          <div class="flex w-full gap-3">
            <inline-svg class="h-5 w-5 text-blues-50" src="/img/icons/new/autoplug.svg" />
            <p>Autoplugs & auto cross-posting</p>
          </div>
          <div class="flex w-full gap-3">
            <inline-svg
              class="h-5 w-5 fill-transparent stroke-blues-50"
              src="/img/icons/new/folder-plus-outline.svg"
            />
            <p class="flex-1">Viral thread hooks and tweet templates</p>
          </div>
        </template>
        <div class="flex w-full items-center gap-3">
          <inline-svg class="h-5 w-5 stroke-blues-50" src="/img/icons/messages.svg" />
          <span class="flex items-center gap-1">
            <p>{{ currentPlan.limits.dailyAutoDMs }}</p>
            <inline-svg class="h-5 w-5 stroke-blues-50" src="/img/icons/new/socials/twitter.svg" />
            <p>Auto-DMs per day</p>
          </span>
        </div>
        <div class="flex w-full gap-3">
          <inline-svg class="h-5 w-5 stroke-blues-50" src="/img/icons/new/siren.svg" />
          <p class="flex-1">
            Increase engagement by watching up to {{ currentPlan.limits.feed.watchedUsers }} users &
            {{ currentPlan.limits.feed.watchedSearchTerms }} keywords
          </p>
        </div>
        <div
          v-if="['standard', 'basic'].includes(currentPlan.name)"
          class="flex w-full items-center gap-3"
        >
          <inline-svg
            class="h-5 w-5 stroke-blues-50"
            src="/img/icons/new/gumroad-sales-outline.svg"
          />
          <p class="flex-1">Automated Gumroad sales</p>
        </div>
        <div class="flex w-full items-center gap-3">
          <template v-if="['standard', 'basic'].includes(currentPlan.name)">
            <inline-svg
              class="h-5 w-5 stroke-blues-50"
              src="/img/icons/new/chat-support-outline.svg"
            />
            <p class="flex-1">Email & Chat support</p>
          </template>
          <template v-if="currentPlan.name === 'business'">
            <inline-svg
              class="h-5 w-5 stroke-blues-50"
              src="/img/icons/new/chat-support-outline.svg"
            />
            <p class="flex-1">Weekend support</p>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import SelectPlanBtn from '@/components/Subscription/SelectPlanBtn.vue';
  import { getPlansBelowCurrentPlan } from '../../../functions/src/util/customerStatus.cjs';
  import { v4 as uuid } from 'uuid';

  export default {
    name: 'PlanCard',
    props: {
      currentPlan: Object,
      dataCy: String,
      isSubscriptionTypeMonthly: Boolean,
    },
    components: { SelectPlanBtn },
    data() {
      return {
        socialMedia: [
          {
            id: uuid(),
            name: 'twitter',
            icon: '/img/icons/new/socials/twitter.svg',
          },
          {
            id: uuid(),
            name: 'facebook',
            icon: '/img/icons/facebook-icon.svg',
          },
          {
            id: uuid(),
            name: 'linkedIn',
            icon: '/img/icons/linkedin-icon.svg',
          },
          {
            id: uuid(),
            name: 'instagram',
            icon: '/img/icons/instagram-icon.svg',
          },
        ],
      };
    },
    computed: {
      planPrices() {
        if (!this.currentPlan.prices.monthly || !this.currentPlan.prices.monthly) return '';

        return this.isSubscriptionTypeMonthly
          ? `${this.currentPlan.prices.monthly.displayPrice}/month`
          : `${this.currentPlan.prices.yearly.displayPrice}/year`;
      },
      planLabel() {
        return this.currentPlan.name.toLocaleLowerCase() ===
          this.currentPlan.label.toLocaleLowerCase()
          ? this.currentPlan.label
          : `${this.currentPlan.label} (${this.currentPlan.name})`;
      },
      schedulingLimit() {
        return `${this.currentPlan.limits.scheduling.value} ${this.currentPlan.limits.scheduling.unit}`;
      },
      planBelowCurrent() {
        const plansBelowCurrent = getPlansBelowCurrentPlan(this.currentPlan.name);

        return plansBelowCurrent[plansBelowCurrent.length - 1];
      },
    },
  };
</script>

<style scoped>
  .normal {
    min-width: 320px;
    max-width: 320px;
    height: 700px;
  }

  .mostPicked {
    min-width: 320px;
    max-width: 320px;
    height: 730px;
  }
</style>
